// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import AppBar from "./components/appbar/appbar";
import ProjectLoader from "./components/projectloader/projectloader";
import { light } from "./theme";
import {
    createMuiTheme,
    ThemeProvider,
    Input,
    CssBaseline,
    createStyles,
    makeStyles,
    Theme,
    Typography,
    Button,
} from "@material-ui/core";
import { SnackbarProvider } from "notistack";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
        },
        header: {
            height: "200px",
        },
        content: {
            display: "flex",
            justifyContent: "center",
        },
        keyInput: {
            marginTop: theme.spacing(2),
            position: "absolute",
        },
        button: {
            marginLeft: theme.spacing(1),
        },
    })
);

function App() {
    const [apiKey, setApiKey] = useState("");
    const [data, setData] = useState([]);
    const appTheme = createMuiTheme(light);
    const classes = useStyles();
    const sendRequest = () => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ key: apiKey }),
        };
        fetch(process.env.REACT_APP_API_URL + "/apps", requestOptions)
            .then((res) => res.json())
            .then((response) => setData(response));
    };
    return (
        <ThemeProvider theme={appTheme}>
            <SnackbarProvider maxSnack={3}>
                <CssBaseline />
                <div className={classes.root}>
                    <AppBar />
                    <div className={classes.content}>
                        <div className={classes.keyInput}>
                            <Typography>Please enter your Azure ressource key.</Typography>
                            <Input color="secondary" onChange={(inputEvent) => setApiKey(inputEvent.target.value)} />
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                onClick={sendRequest}
                            >
                                Submit
                            </Button>
                        </div>
                        <ProjectLoader data={data}></ProjectLoader>
                    </div>
                </div>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
