// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import { uploadProps } from "../../interfaces";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        uploadButton: {
            float: "right",
        },
    })
);

const CsvUpload = (props: uploadProps) => {
    const [file, setFile] = useState("");
    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const id = props.id;
    const version = props.version;
    const classes = useStyles();
    const handleSelectedFile = (event: any) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        await uploadFile();
    };

    const uploadFile = async () => {
        const formData = new FormData();
        formData.append("csvFile", file);
        formData.append("projectID", id);
        formData.append("projectVersion", version.toString());
        const requestOptions = {
            method: "POST",
            body: formData,
        };
        const res = await fetch(process.env.REACT_APP_API_URL + "/upload", requestOptions);
        const resBody = await res.text();
        if (res.status === 200) {
            enqueueSnackbar(resBody, { variant: "success" });
        } else {
            enqueueSnackbar(resBody, { variant: "error" });
        }
    };

    return (
        <div>
            <input type="file" onChange={handleSelectedFile} />
            <Button className={classes.uploadButton} variant="contained" color="secondary" onClick={handleUpload}>
                Upload
            </Button>
        </div>
    );
};

export default CsvUpload;
