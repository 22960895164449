// Copyright (C) 2021 TANNER AG

import React from "react";
import ProjectInfo from "../projectinfo/projectinfo";
import { projectInfo, loaderProps } from "../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        projectList: {
            width: "60%",
            listStyleType: "none",
            marginTop: theme.spacing(12),
        },
    })
);

const ProjectLoader = (props: loaderProps) => {
    const classes = useStyles();
    let projects = props.data;
    return (
        <ul className={classes.projectList}>
            {projects.map((project: projectInfo) => (
                <ProjectInfo
                    id={project.id}
                    name={project.name}
                    description={project.description}
                    activeVersion={project.activeVersion}
                    culture={project.culture}
                />
            ))}
        </ul>
    );
};

export default ProjectLoader;
