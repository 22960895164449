// Copyright (C) 2021 TANNER AG

import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import logo from "./logo.svg";
import { makeStyles, createStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logo: {
            float: "left",
            width: "200px",
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        header: {
            height: "100px",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
        },
        headerTitle: {
            float: "right",
            marginRight: "42%",
        },
    })
);

const SubAppBar = () => {
    const classes = useStyles();
    return (
        <AppBar position="static" className={classes.header}>
            <nav>
                <a href="#" className={classes.logo}>
                    <img src={logo} alt="logo" />
                </a>
            </nav>
        </AppBar>
    );
};

export default SubAppBar;
