// Copyright (C) 2021 TANNER AG

import React from "react";
import { projectInfo } from "../../interfaces";
import CsvUpload from "../csvupload/csvupload";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Card, CardHeader, CardContent, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        projectCard: {
            width: "100%",
            marginBottom: theme.spacing(4),
            display: "inline-block",
        },
        projectName: {
            fontSize: 14,
            fontWeight: 600,
        },
    })
);

const ProjectInfo = (props: projectInfo) => {
    const classes = useStyles();
    return (
        <li>
            <Card className={classes.projectCard}>
                <CardHeader className={classes.projectName} title={props.name} />
                <CardContent>
                    <Typography>{props.description || "No description available."}</Typography>
                    <Typography>{props.activeVersion}</Typography>
                    <Typography>{props.culture}</Typography>
                    <CsvUpload id={props.id} version={props.activeVersion} />
                </CardContent>
            </Card>
        </li>
    );
};

export default ProjectInfo;
