// Copyright (C) 2021 TANNER AG

export const light = {
    palette: {
        primary: {
            main: "#ffffff",
            light: "#f0f0f0",
            dark: "#706e6f",
        },
        secondary: {
            main: "#ffec00",
        },
        background: {
            default: "#f0f0f0",
        },
    },
};
